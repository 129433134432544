<template>
  <div class="req-temp-container">
    <vs-card class="mt-6">
      <div slot="header">
        <h3 class="font-medium payment-page-branding">Default settings</h3>
      </div>
      <form enctype="multipart/form-data">
        <vs-row>
          <p class="text-base font-light mb-4">
            This information will be pre-populate into request templates (you can over ride it any time),  and may be used by default if requests made via an integrated external system are missing necessary fields.
            It may appear on payment pages for your customers, and in communications send to your customers.
          </p>
          <vs-col vs-w="12" vs-lg="12" vs-sm="12">
            <h4 class="mb-4 font-normal">Logo</h4>
            <hr />
          </vs-col>
          <div class="w-full">
            <div class="vx-col w-full vs-con-loading__container">
              <div class="flex upload-img my-5" v-if="image">
                <div class="company-logo-wrap relative">
                  <S3ImageGenerator :is-base64="isBase64(image)" :document="image" :custom-class="'responsive'" :key="image" />
                  <span class="XCircleIcon">
                    <feather-icon icon="XCircleIcon" class="ml-1" @click="clearImage" />
                  </span>
                </div>
              </div>

              <input
                type="file"
                name="companyLogo"
                v-validate="{ size: maxFileSize }"
                class="hidden"
                ref="uploadImgInput"
                accept="image/png"
                data-vv-as="image"
                @change="updateCurrImg"
              />
              <div class="flex upload-img my-5" v-if="!image">
                <div class="force-btn-width flex flex-col md:flex-row justify-content-start flex-wrap md:flex-nowrap items-center">
                  <vs-button flat v-round class="only-border-btn upicon y-centered-icon-text-btn" size="medium" type="border" @click="$refs.uploadImgInput.click()">
                    <upload-icon size="1.5x" class="custom-class"></upload-icon>
                    <u>Upload image</u>
                  </vs-button>

                  <div class="mx-5 my-2 md:my-0">
                    <span class="text-black">Or</span>
                  </div>
                  <vs-button flat v-round class="only-border-btn upicon y-centered-icon-text-btn" size="medium" type="border" @click="logoPopupActive = true">
                    <folder-icon size="1.5x" class="custom-class"></folder-icon>
                    <u>Choose from library</u>
                  </vs-button>
                </div>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('companyLogo')">{{ errors.first("companyLogo") }}</span>
            </div>
          </div>
        </vs-row>
        <vs-row>
          <vs-col vs-w="12" vs-lg="12" vs-sm="12">
            <h4 class="mb-4 mt-10 font-normal">Contact details</h4>
            <hr />
          </vs-col>
          <vs-col vs-w="4" vs-lg="4" vs-sm="12" class="lg:mr-10">
            <div class="mb-5 mt-5">
              <label class="label">Business Name</label>
              <vs-input class="w-full" v-validate="'min:3'" size="large" v-model="companyName" name="companyName" />
              <span class="text-danger text-sm" v-show="errors.has('companyName')">{{ errors.first("companyName") }}</span>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-lg="4" vs-sm="12">
            <div class="mb-5 mt-5">
              <label class="label">ABN</label>
              <br />
              <the-mask
                :class="abn ? 'vs-inputx vs-input--input large' : 'vs-inputx vs-input--input large'"
                :mask="['###########']"
                v-model="abn"
                masked
                name="abn"
                ref="abn"
                v-validate="{ required: false, regex: /^\d{11}$/ }"
              />
              <span class="text-danger text-sm" v-show="errors.has('abn')">{{ errors.first("abn") }}</span>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-lg="4" vs-sm="12" class="lg:mr-10">
            <div class="mb-5 mt-5">
              <label class="label">Email</label>
              <vs-input class="w-full" v-validate="'email'" size="large" v-model="companyEmail" name="companyEmail" />
              <span class="text-danger text-sm" v-show="errors.has('companyEmail')">{{ errors.first("companyEmail") }}</span>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-lg="4" vs-sm="12">
            <div class="mb-5 mt-5">
              <label class="label">Phone</label>
              <br />
              <the-mask
                :class="phoneNumber ? 'vs-inputx vs-input--input large' : 'vs-inputx vs-input--input large'"
                :mask="[checkPhoneNumber]"
                v-model="phoneNumber"
                masked
                name="phoneNumber"
                ref="phoneNumber"
                v-validate="'phoneFormat'"
              />
              <br />
              <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{ errors.first("phoneNumber") }}</span>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-lg="4" vs-sm="12" class="lg:mr-10">
            <div class="mb-5 mt-5">
              <label class="label">Website</label>
              <vs-input class="w-full" v-validate="'url|urlFormat'" size="large" v-model="website" name="website" />
              <span class="text-danger text-sm" v-show="errors.has('website')">{{ errors.first("website") }}</span>
            </div>
          </vs-col>
          <vs-col vs-w="4" vs-lg="4" vs-sm="12">
            <div class="mb-5 mt-5">
              <label class="label">Address</label>
              <vs-input class="w-full" size="large" v-model="address" name="address" />
            </div>
          </vs-col>
        </vs-row>

        <div class="w-full mt-8">
          <div class="flex">
            <h4 class="mb-4">Email masking</h4>
            <div class="ds-wrapper ml-auto">
              <a class="no-underline text-interaction-primary flex gap-4" @click="resetEmailMask">
                <vs-icon icon-size="large" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </div>
          </div>
          <hr />
          <p class="text-base mt-4 font-light">Configure an email mask (a sender name) to apply to the payment request email that {{ appName }} sends to your customers.</p>

          <div class="input-grp">
            <div class="mb-5 mt-5">
              <label class="label w-full">Email mask</label>
              <vs-input
                class="w-full"
                v-validate="'required'"
                size="large"
                v-model="emailMask"
                name="emailMask"
                id="emailMask"
                data-vv-as="email mask"
              />
              <span class="text-danger text-sm" v-show="errors.has('emailMask')">{{ errors.first("emailMask") }}</span>
            </div>
          </div>
        </div>

        <div class="button-section">
          <vs-button :disabled="!validateForm" class="mr-8" size="large" @click="save" v-round>Save</vs-button>
          <a class="cancel-btn mid-blue" @click="onCancel"><u>Cancel</u></a>
        </div>

        <div class="centerx">
          <vs-popup class="holamundo" title="Logo library" :active.sync="logoPopupActive">
            <vs-row class="logo-lib-popup">
              <div @click="clickLogo(logo)" v-for="logo in logoLibrary" :key="logo.logoId">
                <vs-col>
                  <S3ImageGenerator :is-base64="false" :document="logo.logoImage" :custom-class="'selectLogo'" :key="logo.logoImage" />
                </vs-col>
              </div>
            </vs-row>
          </vs-popup>
        </div>
      </form>
    </vs-card>
    <logo-library ref="logoLibrary" :getGlobalSetting="getGlobalSetting" :logoLibrary="logoLibrary" :getLogos="getLogos" :maxFileSize="maxFileSize" v-if="imageLoaded" />
    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
  </div>
</template>

<script>
import { FolderIcon, UploadIcon } from "vue-feather-icons";
import { TheMask } from "vue-the-mask";
import { mapActions } from "vuex";
import LeavePopup from "@/views/components/LeavePopup";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";
import LogoLibrary from "./LogoLibrary.vue";

export default {
  mixins: [leavePopUpMixin],
  components: {
    FolderIcon,
    LeavePopup,
    LogoLibrary,
    S3ImageGenerator,
    TheMask,
    UploadIcon,
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "FlipPay",
      logoPopupActive: false,
      merchantId: "",
      saveDisabled: true,
      companyName: "",
      abn: "",
      companyEmail: "",
      phoneNumber: "",
      website: "",
      address: "",
      image: "",
      logoLibrary: [],
      companyLogo: "",
      popupActive: false, // determines if the leave popup is to be shown
      nextObj: "", // determines the next route,
      isSaved: false,
      childFormDirty: false,
      maxFileSize: "",
      imageLoaded: false,
      emailMask: "",
      defaultImageSize: 500,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    checkPhoneNumber() {
      let mask = "#### ### ###";

      if (this.phoneNumber && this.phoneNumber.length) {
        let numLength = this.phoneNumber.length;
        let value1 = "";
        let value2 = "";

        if (numLength >= 2) {
          value1 = this.phoneNumber.substring(0,2);

          if (value1 === "13") {
            value2 = this.phoneNumber.substring(0,4);
          }
        }

        if (["02", "03", "07", "08"].includes(value1)) {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (value1 === "13") {
          if (parseInt(value2) >= 1300 || value2 === "130" || value2 === "13 0") {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }

      return mask;
    }
  },

  mounted() {
    this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
    this.getGlobalSetting();
    this.getLogos();
  },
  methods: {
    ...mapActions("merchantGlobalSetting", ["fetchSettingsByMerchantId", "saveDefaultCompanyHeader"]),
    ...mapActions("logo", ["fetchLogosByMerchantId"]),

    resetEmailMask() {
      this.emailMask = this.appName;
    },

    getGlobalSetting() {
      this.fetchSettingsByMerchantId().then((res) => {
        let responseData = res.data.data;
        this.maxFileSize = responseData.maxLogoSize ? responseData.maxLogoSize : defaultImageSize;
        this.companyName = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.companyName", "");
        this.abn = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.abn", "");
        this.companyEmail = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.companyEmail", "");
        this.phoneNumber = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.phone", "");
        this.website = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.website", "");
        this.address = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.address", "");
        this.image = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.companyLogo.logoImage", "");
        this.companyLogo = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.companyLogo._id", "");
        this.emailMask = this.$lodashHelper.checkObjectKey(responseData, "companyHeader.emailMask", this.appName);
      });
    },

    getLogos() {
      this.fetchLogosByMerchantId(this.merchantId).then((res) => {
        this.logoLibrary = res.data.data;
        this.imageLoaded = true;
      });
    },

    clickLogo(logo) {
      this.companyLogo = logo._id;
      this.image = logo.logoImage;
      this.logoPopupActive = false;
    },

    async save() {
      try {
        const valid = await this.$validator.validate();
        if (!valid) {
          return false;
        }

        const formData = new FormData();
        formData.append("companyLogo", this.uploadLogo);
        formData.set("companyName", this.companyName);
        formData.set("abn", this.abn);
        formData.set("companyEmail", this.companyEmail);
        formData.set("phone", this.phoneNumber);
        formData.set("website", this.website);
        formData.set("address", this.address);
        formData.set("emailMask", this.emailMask || this.appName);

        if (!this.uploadLogo) {
          formData.set("companyLogo", this.companyLogo);
        }

        this.$vs.loading();

        this.saveDefaultCompanyHeader(formData).then((res) => {
          this.$vs.loading.close();
          this.isSaved = true;
          this.showMessage("Success", "Details updated successfully", "success", "icon-check");
          this.getGlobalSetting();
          this.getLogos();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showMessage("Error", ex.data.message, "danger", "icon-times");
        });
      } catch (ex) {

      }
    },

    onCancel(e) {
      e.preventDefault();
      this.saveDisabled = false;
      this.$router.push({ name: "settings" });
    },

    getPartnerId() {
      return JSON.parse(localStorage.getItem("user"))._id;
    },

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.uploadLogo = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },

    partnerToggle() {
      this.childFormDirty = true;
    },

    clearImage() {
      this.companyLogo = null;
      this.image = null;
      this.childFormDirty = true;
      this.$refs.uploadImgInput.value = "";

      if (this.errors.has("companyLogo")) {
        this.errors.remove("companyLogo");
      }
    },

    isBase64(key) {
      if (typeof key !== "string") {
        return false;
      }

      const validation = new RegExp(/data:image/);

      return validation.test(key);
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
  },
  created() {
    this.$validator.extend("phoneFormat", {
      getMessage: () => "The phone number format is invalid.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null,
    });

    this.$validator.extend("urlFormat", {
      getMessage: () => "The website field is not a valid url.",
      validate: (value) =>
        value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        ) !== null,
    });
  },
  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.childFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    childFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest, previous) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    }
  }
};
</script>

